import React, { useContext, useEffect, useRef } from "react"
import { Context } from "../../utils/Context"
import Layout from "../../components/layout/Layout"
import Carousel from "../../components/carousel/Carousel"
import "./Genero.css"

export default ({ pageContext: { genero } }) => {
  const { dispatch } = useContext(Context)
  const fixedDispatch = useRef()
  // https://medium.com/get-on-board-dev/deshabilitar-react-hooks-exhaustive-deps-es-t%C3%B3xico-d35feda7eb04
  useEffect(() => {
    fixedDispatch.current = dispatch
  })
  useEffect(() => {
    genero === "mujer"
      ? fixedDispatch.current({
          type: "WOMEN",
        })
      : fixedDispatch.current({
          type: "MEN",
        })
  }, [genero])

  return (
    <Layout type="HM" className="gender" title="gender">
      <Carousel />
    </Layout>
  )
}
