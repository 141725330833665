import React, { useEffect, useState, useContext } from "react"
import { Context } from "../../utils/Context"
import ButtonLink from "../button/ButtonLink"
import carousel1 from "../../images/carousel1.jpg"
import carousel2 from "../../images/carousel2.jpg"
import carousel3 from "../../images/carousel3.jpg"
import carousel4 from "../../images/carousel4.jpg"
import carousel5 from "../../images/carousel5.jpg"
import carousel6 from "../../images/carousel6.jpg"
import carousel7 from "../../images/carousel7.jpg"
import "./Carousel.css"

const arrWomen = [
  { img: carousel1, txt: "NEW IN", url: "/categoria" },
  { img: carousel2, txt: "PV21", url: "/categoria" },
  { img: carousel3, txt: "COMPRAR", url: "/categoria" },
  { img: carousel4, txt: "+++", url: "/categoria" },
]

const arrMen = [
  { img: carousel5, txt: "OTROS", url: "/categoria" },
  { img: carousel6, txt: "BANNERS", url: "/categoria" },
  { img: carousel7, txt: ">", url: "/categoria" },
]

export default () => {
  const { state } = useContext(Context)
  const [active, setActive] = useState(0)
  const data = state.config.isWomen ? arrWomen : arrMen
  useEffect(() => {
    const interval = setInterval(() => {
      setActive(active => {
        if (active < data.length - 1) {
          return active + 1
        } else {
          return 0
        }
      })
    }, 8000)
    return () => clearInterval(interval)
  }, [data])
  return (
    <div className="carousel">
      {data.map((item, i) => {
        return (
          <picture
            key={i}
            className={`carousel-item ${active === i ? "active" : ""}`}
          >
            <img className="carousel-item-img" src={item.img} alt="img" />
            <ButtonLink
              block
              outline
              className="carousel-item-btn-link"
              url={item.url}
            >
              {item.txt}
            </ButtonLink>
          </picture>
        )
      })}
    </div>
  )
}
